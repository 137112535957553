import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Container } from 'reactstrap';
import Link from 'next/link';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { nextAsset } from '../../../components/nextAsset';
import style from './IndexNeverForget.module.scss';
import Wave from '../../../../public/statics/svgs/wave.svg';
import WaveCenter from '../../../../public/statics/svgs/wave-center.svg';
import OvalConfetti1 from '../../../../public/statics/svgs/oval-confetti1.svg';
import OvalConfetti2 from '../../../../public/statics/svgs/oval-confetti2.svg';
import OvalConfetti3 from '../../../../public/statics/svgs/oval-confetti3.svg';
import OvalOverlayConfetti from '../../../../public/statics/svgs/oval-overlay-confetti.svg';

const IndexNeverForgetConfetti1: React.FC = () => {
  return (
    <div className={classNames(style.ovalConfetti, style.ovalConfetti1)}>
      <OvalConfetti1 />
      <div className={classNames(style.ovalConfettiImage, style.lips)}>
        <img className="img-fluid" src={nextAsset('images/design/lips.png')} alt="Lips" />
      </div>
    </div>
  );
};
const IndexNeverForgetConfetti2: React.FC = () => {
  return (
    <div className={classNames(style.ovalConfetti, style.ovalConfetti2)}>
      <OvalConfetti2 />
      <div className={classNames(style.ovalConfettiImage, style.eyes)}>
        <img className="img-fluid" src={nextAsset('images/design/eyes.png')} alt="Eyes" />
      </div>
    </div>
  );
};
const IndexNeverForgetConfetti3: React.FC = () => {
  return (
    <div className={classNames(style.ovalConfetti, style.ovalConfetti3)}>
      <OvalConfetti3 />
      <div className={classNames(style.ovalConfettiImage, style.hand)}>
        <img className="img-fluid" src={nextAsset('images/design/hand.png')} alt="Hand" />
      </div>
      <div className={classNames(style.ovalConfettiOverlay)}>
        <OvalOverlayConfetti />
      </div>
    </div>
  );
};
const IndexNeverForgetBrainComponent: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  return (
    <div className={style.wavesContainer}>
      <div className={style.waves}>
        <div className={style.wavesBackground}>
          <Wave />
        </div>
        <div className={style.wavesCenter}>
          <div className={style.wavesCenterBackground}>
            <WaveCenter />
          </div>
          <div className={style.wavesCenterInner}>
            <img className="img-fluid" src={nextAsset('images/design/brain.png')} alt="Brain" />
          </div>
        </div>
      </div>
      <div className={classNames('text-heading text-shadow-primary-opacity', style.wavesTitle)}>{children}</div>
    </div>
  );
};
const IndexNeverForgetBrainContent1: React.FC = () => {
  const intl = useIntl();
  return (
    <p>
      {intl.formatMessage(
        {
          id: 'web.home.host-your-own-game.sleek-graphics',
          defaultMessage:
            'Sleek graphics, cool animations and fun sound effects make for a true <b>game show</b> <b>experience.</b>'
        },
        {
          b: (children: any) => <span className={style.textStroke}>{children}</span>
        }
      )}
    </p>
  );
};
const IndexNeverForgetBrainContent2: React.FC = () => {
  const intl = useIntl();
  return (
    <p>
      {intl.formatMessage(
        {
          id: 'web.home.host-your-own-game.ready-made-sets',
          defaultMessage:
            '<b>Ready-made</b> <b>sets of questions</b> or supply your own to suit your group of contestants.'
        },
        {
          b: (children: any) => <span className={style.textStroke}>{children}</span>
        }
      )}
    </p>
  );
};
const IndexNeverForgetBrainContent3: React.FC = () => {
  const intl = useIntl();
  return (
    <p>
      {intl.formatMessage(
        {
          id: 'web.home.host-your-own-game.feel-free-to-remove-logo',
          defaultMessage:
            'Feel free to get rid of our Quizado logo and <b>personalize</b> <b>the game</b> with your own company branding.'
        },
        {
          b: (children: any) => <span className={style.textStroke}>{children}</span>
        }
      )}
    </p>
  );
};
export const IndexNeverForget: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div>
      <div className={style.header}>
        {intl.formatMessage(
          {
            id: 'web.home.host-your-own-game.never-forget',
            defaultMessage: 'Let us bring you{br}an event that your guests... <b>will not forget.</b>'
          },
          {
            b: (children: any) => <IndexNeverForgetBrainComponent>{children}</IndexNeverForgetBrainComponent>,
            br: <br />
          }
        )}
      </div>
      <Container className={classNames('d-none d-lg-flex', style.ovals)}>
        <IndexNeverForgetConfetti1 />
        <div className={style.content}>
          <IndexNeverForgetBrainContent1 />
          <IndexNeverForgetBrainContent2 />
          <IndexNeverForgetBrainContent3 />
        </div>
        <div className="d-flex flex-column align-items-center align-self-stretch justify-content-around">
          <IndexNeverForgetConfetti2 />
          <IndexNeverForgetConfetti3 />
        </div>
      </Container>
      <Container className={classNames('d-flex d-lg-none mt-5', style.ovalsMobile, style.content)}>
        <div className={style.ovalsMobileInner}>
          <IndexNeverForgetConfetti2 />
          <IndexNeverForgetBrainContent1 />
        </div>
        <div className={style.ovalsMobileInner}>
          <IndexNeverForgetBrainContent2 />
          <IndexNeverForgetConfetti1 />
        </div>
        <div className={style.ovalsMobileInner}>
          <IndexNeverForgetConfetti3 />
          <IndexNeverForgetBrainContent3 />
        </div>
      </Container>
      <Container className="text-center">
        <Link prefetch={false} href="/features" passHref legacyBehavior>
          <Button color="primary" className="text-accent2 text-heading py-3 px-5 btn-mobile-border">
            {intl.formatMessage({
              id: 'web.home.host-your-own-game.learn-more',
              defaultMessage: 'Learn more'
            })}
            <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
          </Button>
        </Link>
      </Container>
    </div>
  );
});
