import React from 'react';
import HomeOvals from '../../../../public/statics/svgs/home-ovals.svg';

import style from './IndexBackground.module.scss';

export const IndexBackground: React.FC = React.memo(() => {
  return (
    <div className={style.background}>
      <div className={style.inner}>
        <HomeOvals />
      </div>
    </div>
  );
});
