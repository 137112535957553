import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { NextSlider } from '../../../components/NextSlider';
import { nextAsset } from '../../../components/nextAsset';
import style from './IndexGallerySlider.module.scss';

const IndexGallerySliderElement: React.FC<{ src: string }> = ({ src }) => {
  const intl = useIntl();
  return (
    <div className={style.element}>
      <img
        src={nextAsset(src, false, 'w=800')}
        className={classNames('img-fluid', style.elementImage)}
        alt={intl.formatMessage({
          id: 'web.app.name',
          defaultMessage: 'Quizado'
        })}
      />
    </div>
  );
};
const images = [
  'gallery/10.jpg',
  'gallery/assisted-living/1.jpg',
  'gallery/9.jpg',
  'gallery/krystian/1.jpg',
  'gallery/2.jpg',
  'gallery/assisted-living/2.jpg',
  'gallery/3.jpg',
  'gallery/krystian/3.jpg',
  'gallery/12.jpg',
  'gallery/krystian/2.jpg',
  'gallery/6.jpg',
  'gallery/assisted-living/3.jpg'
];
export const IndexGallerySlider: React.FC = React.memo(() => {
  return (
    <NextSlider
      lazyHeight={285}
      arrows={false}
      dots={false}
      centerMode
      autoplay
      className="center talking-about-us-slider mt-4"
      infinite
      centerPadding="60px"
      slidesToShow={3}
      swipeToSlide
      responsive={[
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            centerPadding: '30px'
          }
        }
      ]}
    >
      {images.map((image) => (
        <IndexGallerySliderElement key={image} src={image} />
      ))}
    </NextSlider>
  );
});
