import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import { DownloadButtons } from '../../../components/DownloadButtons';
import style from './IndexDownloadNow.module.scss';
import BlackBg from '../../../../public/statics/svgs/black-bg.svg';
import Walrus2 from '../../../../public/statics/svgs/walrus-2.svg';

export const IndexDownloadNow: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.background}>
          <BlackBg />
        </div>
        <Container className="position-relative">
          <div className={style.header}>
            <h2 className="text-heading text-shadow-primary-on-black text-white">
              {intl.formatMessage({
                id: 'web.download-now.ready-to-feud',
                defaultMessage: 'Ready to Feud?'
              })}
            </h2>
            <h2 className="text-heading text-shadow-primary-on-black text-secondary">
              {intl.formatMessage({
                id: 'web.home.download-now.download-the-game',
                defaultMessage: 'Download the game and play our demo set.'
              })}
            </h2>
          </div>
          <div className="mt-5">
            <DownloadButtons onDarkBg />
          </div>
        </Container>
        <div className={style.walrus}>
          <Walrus2 />
        </div>
      </div>
    </div>
  );
});
