import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import style from './IndexHostYourOwnGame.module.scss';
import Crocodile1 from '../../../../public/statics/svgs/crocodile-1.svg';
import Sloth4 from '../../../../public/statics/svgs/sloth-4.svg';
import Confetti1 from '../../../../public/statics/svgs/confetti1.svg';
import Confetti2 from '../../../../public/statics/svgs/confetti2.svg';

export const IndexHostYourOwnGame: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={style.container}>
      <Container className="position-relative">
        <div className={style.header}>
          <div className={classNames('d-none d-lg-block', style.crocodile)}>
            <Crocodile1 />
          </div>
          <h2 className="text-heading text-shadow-accent2 text-primary text-center">
            {intl.formatMessage(
              {
                id: 'web.home.host-your-own-game.title',
                defaultMessage: 'Host your own Family Feud <b>game</b>'
              },
              {
                b: (children: any) => (
                  <span className={classNames('text-shadow-none', style.textStroke)}>{children}</span>
                ),
                br: <br />
              }
            )}
          </h2>
          <div className={classNames('d-none d-lg-block', style.sloth)}>
            <Sloth4 />
          </div>
        </div>
        <div className="d-flex d-lg-none flex-row justify-content-around">
          <div className={style.crocodileMobile}>
            <Crocodile1 />
          </div>
          <div className={style.spacer} />
          <div className={style.slothMobile}>
            <Sloth4 />
          </div>
        </div>
      </Container>
      <div className={style.lines}>
        <div className={style.line1}>
          <div>
            {intl.formatMessage(
              {
                id: 'web.home.host-your-own-game.the-thrill.title',
                defaultMessage: 'The <b>thrill.</b>'
              },
              {
                b: (children: any) => <span>{children}</span>
              }
            )}
          </div>
        </div>
        <div className={style.confetti2}>
          <Confetti2 />
        </div>
        <div className={style.line2}>
          <div>
            {intl.formatMessage(
              {
                id: 'web.home.host-your-own-game.the-excitement.title',
                defaultMessage: 'The <b>excitement.</b>'
              },
              {
                b: (children: any) => <span>{children}</span>
              }
            )}
          </div>
        </div>
        <div className={style.line3}>
          {intl.formatMessage(
            {
              id: 'web.home.host-your-own-game.the-unpredictability.title',
              defaultMessage: 'The <b>unpredictability.</b>'
            },
            {
              b: (children: any) => <span>{children}</span>
            }
          )}
        </div>
        <div className={style.confetti1}>
          <Confetti1 />
        </div>
      </div>
    </div>
  );
});
