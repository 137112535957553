import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import { useScrollPosition } from '../../../components/hooks/useScrollPosition';
import style from './CarouselFullPage.module.scss';

const getCurrentStep = (
  stepsCount: number,
  scrollY: number,
  height: number,
  heightMultiplier: number,
  offset: number
) => {
  const offsetPerHeight = offset ? offset * (height - height / heightMultiplier) : 0;
  return Math.min(
    stepsCount + offsetPerHeight,
    Math.max(0, (-1 * scrollY * stepsCount) / (height - height / heightMultiplier))
  );
};

interface CarouselFullPageProps {
  children: (currenStep: number) => React.ReactNode;
  steps: number;
  offset?: number;
  className?: string;
}

export const CarouselFullPage: React.FC<CarouselFullPageProps> = ({ className, children, offset, steps }) => {
  const [scrollY, setScrollY] = useState<number>(0);
  const [height, setHeight] = useState<number>(1);
  const viewportRef = useRef<HTMLDivElement>(null);

  const heightMultiplier = 5;

  const currentStep = useMemo(
    () => getCurrentStep(steps, scrollY, height, heightMultiplier, offset ?? 0),
    [steps, scrollY, height, offset]
  );
  useScrollPosition((info) => {
    const ref = viewportRef.current;
    if (!ref) {
      return;
    }

    const dom = ref.getBoundingClientRect();
    const h = dom.height;
    setScrollY(info.y);
    setHeight(h);
  }, viewportRef);

  const containerClassname: CSSProperties = {
    alignItems: 'flex-start'
  };
  return (
    <div
      className={className || 'd-flex my-5'}
      style={{ height: `${heightMultiplier * 100}%`, ...containerClassname }}
      ref={viewportRef}
    >
      <div style={{ height: `${100 / heightMultiplier}%`, position: `sticky`, top: 0 }} className={style.container}>
        {children(currentStep)}
      </div>
    </div>
  );
};
