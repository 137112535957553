import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import { listOfHostedAtCompanies } from './listOfHostedAtCompanies';
import { nextAsset } from '../../../components/nextAsset';
import styles from './IndexHostedAt.module.scss';

export const IndexHostedAt: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <Container className="mt-5 pt-5">
      <Row>
        <Col>
          <h2 className="text-heading text-shadow-accent2 text-primary text-center">
            {intl.formatMessage({
              id: 'web.home.hosted-at.title',
              defaultMessage: 'Hosted at'
            })}
          </h2>
          <div className={styles.container}>
            {listOfHostedAtCompanies.map(({ name, url, image }) => (
              <a
                href={url}
                className={styles.element}
                key={image}
                style={{ backgroundImage: `url("${nextAsset(`images/hostedAt/${image}`)}")` }}
              >
                {name}
              </a>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
});
