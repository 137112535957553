import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { DownloadButtons } from '../../../components/DownloadButtons';
import Antelope2 from '../../../../public/statics/svgs/antelope-2.svg';

import style from './IndexTop.module.scss';
import { WatchVideoComponent } from '../../../components/WatchVideoComponent';
import { NavbarComponent } from '../../../layouts/Navbar';
import { WatchVideoComponentMobile } from '../../../components/WatchVideoComponentMobile';

export const IndexTop: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={classNames(style.wrapper)}>
      <div className={classNames('position-relative d-flex flex-column', style.container)}>
        <NavbarComponent />
        <Container className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          <div className="align-items-center d-flex flex-row">
            <div className="mr-n2 d-none d-lg-flex">
              <Antelope2 style={{ width: '100%' }} />
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div className="d-flex flex-row">
                <div className="mr-n2 d-flex d-lg-none">
                  <Antelope2 style={{ width: 120 }} />
                </div>
                <div className="d-flex flex-row flex-wrap flex-lg-nowrap justify-content-center">
                  <div>
                    <h1
                      className={classNames('text-heading text-shadow-primary-opacity mb-2 mb-md-3 mt-5', style.header)}
                    >
                      {intl.formatMessage(
                        {
                          id: 'web.home.heading.title',
                          defaultMessage: 'Make your next event more fun with <strong>QUIZADO!</strong>'
                        },
                        {
                          strong: (inner: any) => <span className="brand">{inner}</span>
                        }
                      )}
                    </h1>

                    <h2 className={style.description}>
                      <FormattedMessage
                        id="web.home.heading.subtitle"
                        defaultMessage="A great way to entertain guests by running a game show in the Family Feud style."
                      />
                    </h2>
                  </div>
                  <div className="ml-0 ml-lg-5 mt-5 mt-lg-0  d-none d-lg-flex">
                    <WatchVideoComponent />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-start flex-column mt-5">
                <div>
                  <div>
                    <WatchVideoComponentMobile />
                  </div>
                  <div>
                    <DownloadButtons onDarkBg />
                  </div>
                  <p className={classNames('text-white mt-3 mb-4 text-center', style.freeToStart)}>
                    (
                    <FormattedMessage
                      id="web.home.free-to-get-started"
                      defaultMessage="100% free to get started. No credit card required."
                    />
                    )
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
});
