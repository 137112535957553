import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

export const QuizadoLi: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  return (
    <li>
      <FontAwesomeIcon icon={faCheck} className="text-primary mr-2" /> {children}
    </li>
  );
};
