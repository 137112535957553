import React, { useCallback, useLayoutEffect, useRef } from 'react';

const isBrowser = typeof window !== `undefined`;

interface ScrollInfo {
  x: number;
  y: number;
}

const getScrollPosition: (element: HTMLElement | null, useWindow: boolean) => ScrollInfo = (
  element,
  useWindow
): { x: number; y: number } => {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ?? document.body;
  const position = target.getBoundingClientRect();

  return useWindow ? { x: window.scrollX, y: window.scrollY } : { x: position.left, y: position.top };
};

export const useScrollPosition = (
  effect: (currPos: ScrollInfo, prevPos: ScrollInfo) => void,
  element: React.RefObject<HTMLElement> | undefined,
  useWindow = false
): void => {
  const position = useRef<ScrollInfo>(getScrollPosition(element ? element.current : null, useWindow));

  const callBack = useCallback(() => {
    const currPos = getScrollPosition(element ? element.current : null, useWindow);
    effect(currPos, position.current);
    position.current = currPos;
  }, [effect, element, useWindow]);

  useLayoutEffect(() => {
    if (!isBrowser) {
      return undefined;
    }

    window.addEventListener('scroll', callBack, {
      passive: true
    });

    return () => window.removeEventListener('scroll', callBack);
  });
};
