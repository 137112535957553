import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import style from './IndexParagraphs.module.scss';
import { nextAsset } from '../../../components/nextAsset';
import WalrusEvenMe from '../../../../public/statics/svgs/walrus-even-me.svg';

export const IndexParagraphs: React.FC = React.memo(() => {
  const intl = useIntl();
  const { locale } = useRouter();
  return (
    <div className={style.container}>
      <Container className="d-flex flex-column-reverse flex-lg-row overflow-hidden">
        <div className={style.sweatyFace}>
          <img src={nextAsset('images/design/sweaty-face.png')} className="img-fluid" alt="Sweaty Face" />
        </div>
        <div className={style.ovalSalmon}>
          {intl.formatMessage(
            {
              id: 'web.home.host-your-own-game.the-thrill.subtitle',
              defaultMessage:
                '<b>Quizado</b> brings you all the <b>high-spirited</b> fun of a classic TV game show. See which team can come up with the best responses to the <b>fast-paces rounds</b> of survey questions. The contestants have to be quick on their feet before the opponent buzzes in! If they can’t <b>handle the pressure</b>, you’ll end up witnessing some pretty goofy answers… 🤪'
            },
            {
              b: (children: any) => <span>{children}</span>
            }
          )}
        </div>
        <div className={style.tv}>
          <img src={nextAsset('images/design/tv.png')} className="img-fluid" alt="TV" />
        </div>
      </Container>
      <Container className="mt-5 d-flex flex-column">
        <div>
          <div className={style.middleTitle}>
            {intl.formatMessage(
              {
                id: 'web.home.host-your-own-game.excitement.title',
                defaultMessage: 'There’s nothing like the{br}<b>action</b> and <b>excitement</b> of{br}a game show!'
              },
              {
                b: (children) => <span>{children}</span>,
                br: <br />
              }
            )}
          </div>
        </div>
      </Container>
      <Container
        className={classNames(
          'd-flex flex-column-reverse flex-lg-row pb-5 mb-5 overflow-hidden',
          style.secondParagraph
        )}
      >
        <div className={style.walrus}>
          <WalrusEvenMe />
        </div>
        <div className={style.ovalBlue}>
          {intl.formatMessage(
            {
              id: 'web.home.host-your-own-game.excitement.subtitle',
              defaultMessage:
                '<b>Quizado</b> will draw <b>participation and enthusiasm</b> for any group - big or small - in either a <b>live or virtual set up</b>. Unlike other forms of group entertainment, a live game show is a <b>great way to get everyone involved</b>. It’s both fun to play and watch!'
            },
            {
              b: (children: any) => <span>{children}</span>
            }
          )}
        </div>
        <div className={style.face}>
          <img src={nextAsset(`images/design/face-${locale}.png`)} className="img-fluid" alt="Sweaty Face" />
        </div>
      </Container>
    </div>
  );
});
