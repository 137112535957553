import React, { useCallback, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import style from './OnVisibleAnimatedElement.module.scss';

export const OnVisibleAnimatedElement: React.FC<React.PropsWithChildren<any>> = React.memo(({ children }) => {
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const onVisible = useCallback((isVisible: boolean) => {
    if (isVisible) {
      setShouldAnimate(true);
    }
  }, []);
  return (
    <VisibilitySensor partialVisibility onChange={onVisible} intervalDelay={300}>
      <div className="overflow-hidden">
        <div
          className={classNames(style.container, {
            [style.visible]: shouldAnimate,
            [style.invisible]: !shouldAnimate
          })}
        >
          {children}
        </div>
      </div>
    </VisibilitySensor>
  );
});
