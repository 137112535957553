import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import style from './IndexLookAtTheFun.module.scss';
import GalleryBg from '../../../../public/statics/svgs/gallery-bg.svg';
import Giraffe1 from '../../../../public/statics/svgs/giraffe-1.svg';
import { IndexGallerySlider } from './IndexGallerySlider';

export const IndexLookAtTheFun: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.background}>
          <GalleryBg />
        </div>
        <Container className="position-relative">
          <div className={style.header}>
            <h2 className="text-heading text-shadow-primary-opacity text-white text-center">
              {intl.formatMessage(
                {
                  id: 'web.home.look-at-fun.title',
                  defaultMessage: 'Just look at all{br}<b>the fun</b> our{br}customers had'
                },
                {
                  b: (children) => <span className="text-primary text-nowrap">{children}</span>,
                  br: <br />
                }
              )}
            </h2>

            <div className={classNames('d-none d-lg-block', style.giraffe)}>
              <Giraffe1 />
            </div>
          </div>
          <IndexGallerySlider />
        </Container>
      </div>
      <div className={classNames('d-block d-lg-none', style.giraffeMobile)}>
        <Giraffe1 />
      </div>
    </div>
  );
});
