import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { QuizadoLi } from '../../../components/QuizadoLi';
import style from './IndexBestEvent.module.scss';
import BgAccent1 from '../../../../public/statics/svgs/bg-accent1.svg';
import Characters from '../../../../public/statics/svgs/characters.svg';
import CharactersMobile from '../../../../public/statics/svgs/characters-mobile.svg';

export const IndexBestEvent: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.background}>
          <BgAccent1 />
        </div>
        <Container className="position-relative">
          <div className={style.header}>
            <h2 className="text-heading text-shadow-primary text-white ml-n5">
              {intl.formatMessage(
                {
                  id: 'web.home.best-event.title',
                  defaultMessage: 'Your best event{br}ever is about to{br}happen'
                },
                {
                  br: <br />
                }
              )}
            </h2>
          </div>
          <div className={style.content}>
            <div className={classNames(style.characters, 'd-none d-lg-flex')}>
              <Characters />
            </div>
            <div className={classNames(style.charactersMobile, 'd-flex d-lg-none')}>
              <CharactersMobile />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <p>
                {intl.formatMessage({
                  id: 'web.home.best-event.mediocre-events',
                  defaultMessage:
                    'Mediocre events aren’t fun so don’t settle for something ordinary. You want something that stands out and screams fun.'
                })}
              </p>
              <p>
                {intl.formatMessage(
                  {
                    id: 'web.home.best-event.great-entertainment',
                    defaultMessage:
                      '<b>Quizado</b> provides great entertainment for all kinds of gatherings, whether it’s a family, corporate, community or school event.'
                  },
                  {
                    b: (children: any) => <strong>{children}</strong>
                  }
                )}
              </p>
            </div>
          </div>
          <div className={style.list}>
            <p className="font-weight-bold">
              {intl.formatMessage({
                id: 'web.home.best-event.list.title',
                defaultMessage: 'Our game is ideal for:'
              })}
            </p>
            <ul className="text-heading text-shadow-primary-thin text-white list-unstyled fa-ul m-0">
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.weddings',
                  defaultMessage: 'WEDDINGS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.bridal-showers',
                  defaultMessage: 'BRIDAL SHOWERS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.icebreakers',
                  defaultMessage: 'ICEBREAKERS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.corporate-trainings',
                  defaultMessage: 'CORPORATE TRAININGS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.virtual-team-meetings',
                  defaultMessage: 'VIRTUAL TEAM MEETINGS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.get-together',
                  defaultMessage: 'GET-TOGETHERS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.birthdays',
                  defaultMessage: 'BIRTHDAYS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.anniversaries',
                  defaultMessage: 'ANNIVERSARIES'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.picnics',
                  defaultMessage: 'PICNICS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.trade-shows',
                  defaultMessage: 'TRADE SHOWS'
                })}
              </QuizadoLi>
              <QuizadoLi>
                {intl.formatMessage({
                  id: 'web.home.best-event.list.parties',
                  defaultMessage: 'PARTIES'
                })}
              </QuizadoLi>
            </ul>
            <p className="mt-5">
              <strong>
                {intl.formatMessage({
                  id: 'web.home.best-event.and-much-more',
                  defaultMessage: 'AND MUCH MORE!'
                })}
              </strong>
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
});
